<template>
  <a href="javascript: void(0)" :data-synth="'combo-' + item.uuid" @click="redirectPage" class="item-menu item-combo" v-bind:class="classCols" v-bind:title="item.Description" >
    <div class="inner">
      <div class="menu-image while-tooltip bg1">
        <img v-if="item.image && item.image.desktop_thumbnail"
                   :src="item.image.desktop_thumbnail" :alt="item.name"/>
      </div>
      <div class="flag flag--"><span class="flag__inner"></span></div>
      <div class="bot">
        <div class="menu-name text-left" style="z-index: 1">
            {{item.name}}<span class="t10 bold label tag tag-"></span>
        </div>
        <div class="menu-description" style="z-index: 1"><div v-if="item.marketing_description" tag="div" v-html="item.marketing_description" /></div>
        <!--<div class="menu-description hide hidden-xs" style="z-index: 1"><nl2br v-if="item.main_description" tag="div" :text="item.main_description" /></div>-->
        <!--<ellipsis :data="item.marketing_description"-->
                  <!--:line-clamp="2"-->
                  <!--:line-height="'24px'"-->
                  <!--class="combo-des"-->
                  <!--v-if="item.marketing_description"-->
        <!--&gt;</ellipsis>-->


      <div class="fw600 pr10 t16" style="z-index: 1;border-top: 1px solid #f1f1f1;" v-if="checkPosition(this)">
        <div style="width: 100%; display: flex; flex: 1" class="hide">
          <i class="icon-delivery xs-mr-5 xs-hide md-block hide"></i>
          <i class="icon-collection xs-mr-5 xs-hide md-block hide"></i>
        </div>
        <div class="block m-10" style="float: left;">
          <button data-synth="select-combo-button" class="btn btn-success btn-select-combo item-btn block">
            <span class="pr-10 pl-10">{{ $t('select') }}</span>
          </button>
        </div>
        <div style="width: 100%; text-align: right; line-height: 30px; margin: 12px 0;">
          <div v-if="$const.COMBO_PRICE_VIEW_MODE === 0">
            <div v-if="item.display_price > 0">
              <span v-if="!isNaN(item.display_price)" v-html="generatePrice(item)"></span>
              <span v-else>
                {{ item.display_price }}
              </span>
            </div>
            <div v-else class="t13" style="line-height: 32px">&nbsp;</div>
          </div>
          <div v-if="$const.COMBO_PRICE_VIEW_MODE === 1">
            <div v-if="item.price > 0">
              <span v-if="!isNaN(item.display_price)" v-html="generatePrice_otp2(item)"></span>
              <span v-else>
                {{ item.display_price }}
              </span>
            </div>
            <div v-else class="t13">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </a>
</template>
<script>
import Ellipsis from '@/components/partials/Ellipsis'

export default {
  components: {
    Ellipsis
  },
  props: ['item', 'cols'],
  data () {
    return {
      classCols: 'cols-' + this.cols
    }
  },
  methods: {
    redirectPage () {
      if (this.checkPosition(this)) {
        this.evenTrackingProductClick(this, this.item, 'direct-product-selection')
        this.$root.$router.push({name: 'combo-detail', params: {id: this.item.uuid}})
      } else {
        this.$root.$router.push({name: 'home', query: { isShowWarning: '1', focusLocalization: true, callback: 'combo-detail/' + this.item.uuid }})
      }
    },
    generatePrice (item) {
      return this.$t('format_currency', { currency: this.$const.CURRENCY, amount: this.formatNumDisplay(item.display_price), start: '<span class="t13"><span class="font-08em cl-78838c fw-normal">', end: '</span></span>' })
    },
    generatePrice_otp2 (item) {
      return this.$t('format_currency_otp2', { currency: this.$const.CURRENCY, price: this.formatNumDisplay(item.price), start: '<span class="t13"><span class="font-08em cl-78838c fw-normal">', end: '</span></span>', display_price: this.formatNumDisplay(item.display_price), start_display_price: '<span class="text-red display-price">', end_display_price: '</span>'})
    }
  }
}
</script>

<style>
.text-red {
  color: #da291c;
}
.display-price {
  background-color: rgb(255, 243, 248);
  text-decoration: line-through;
  border-color: #da291c;
  border-radius: 4px;
  border-width: 2px;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
